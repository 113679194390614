import React from 'react'

const conversions = {
  sm: 3.28084,
  lg: 0.0006213711922
}

export const getLocale = () =>{
  const locale = Boolean(typeof window !== 'undefined') ? window.navigator.userLanguage || window.navigator.language : undefined
  return locale || 'en-US'
}

export const formatNumericDate = (date) => {
  if (!date) return 
  var options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
  }
  date = new Date(date)
  return date.toLocaleDateString(getLocale(), options)
}

export const formatDate = (date, withTime) => {
  if (!date) return 
  var options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  }
  if (withTime) {
    options.hour = 'numeric'
    options.minute = 'numeric'
  }
  date = new Date(date)
  return date.toLocaleDateString(getLocale(), options)
}

export const formatTinyDate = (date) => {
  const options = { month: 'short', day: '2-digit' }
  date = new Date(date)
  return `${date.toLocaleDateString(getLocale(), options)}`
}

export const formatLongDate = (date) => {
  const options = {
    month: 'long',
    day: '2-digit',
    year: 'numeric'
  }
  date = new Date(date)
  return `${date.toLocaleDateString(getLocale(), options)}`
}

export const formatShortDate = (date) => {
  const options = {
    month: 'short',
    day: '2-digit',
    year: 'numeric'
  }
  date = new Date(date)
  return `${date.toLocaleDateString(getLocale(), options)}`
}

export const formatTime = (date) => {
  var options = {
    hour: 'numeric',
    minute: 'numeric'
  }
  date = new Date(date)
  return date.toLocaleTimeString(getLocale(), options)
}

export const convertMilesToMeters = (val) => {
  return val * 1609.34
}

export const convertFeetToMeters = (val) => {
  return val * 0.3048
}

export const converter = (num, imperial, type = 'lg') => {
  if (imperial || imperial === null) {
    const c = conversions[type] || conversions.lg
    num = num * c
  } else if (type === 'lg') {
    num = num / 1000 // m to km conversion
  }
  return num
}

export const formatMetric = (num, precision, type = 'lg', imperial) => {
  return formatNumber(converter(num, imperial, type), precision)
}

export const displayMeasurement = (imperial, type = 'lg', toLower, short, forceMiles) => {
  let m
  if (imperial || imperial === null) {
    let miles = forceMiles ? 'Miles' : 'Mi'
    m = type == 'lg' ? (short ? miles : 'Miles') : (short ? 'Ft' : 'Feet')
  } else {
    m = type == 'lg' ? (short ? 'Km' : 'Kilometers') : (short ? 'M' : 'Meters')
  }
  if (toLower) return m.toLowerCase()
  return m
}

export const formatNumber = (num, precision) => {
  if (!num && num !== 0) num = 0
  const options = {
    minimumFractionDigits: precision || 0,
    maximumFractionDigits: precision || 0
  }
  return parseFloat(num).toLocaleString(undefined, options)
}

export const addParagraphs = (text) => {
  if (!text) return ''
  const EXP = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
  const mapLinks = (text) => { 
    return text.split(EXP).map((chunk, i) => {
      if (chunk.match(EXP)) {
        if (!chunk.match(/^http/i)) chunk = `http://${chunk}`
        return <a href={chunk} target='_blank' rel='noreferrer' key={i}>{chunk.replace(/^https?:\/\//i, '')}</a>;
      }
      return chunk
    });
  }

  const splitItems = text.trim().replace(/^\s+|\s+$/g, '').split('\n')
  return splitItems.map((item, key, index) => {
    return <React.Fragment key={key}>{mapLinks(item)}{index !== splitItems.length - 1 && <br/>}</React.Fragment>
  })
}

export const formatMinutes = (num) => {
  return formatNumber(Math.round((num / 60) * 100) / 100, 0)
}

export const formatDurationGoal = (num) => {
  const hours = Math.floor((num / 60) / 60)
  const minutes = (num / 60) - (hours * 60) // precision problem? ui only lets you set by minute, so not yet
  if (hours) return `${formatNumber(hours)} hour${hours > 1 ? 's' : ''}${minutes ? `, ${minutes} minute${minutes > 1 ? 's' : ''}` : ''}`
  return `${minutes} minutes`
}

export const formatDuration = (num, round) => {
  if (!num) return 0
  let minutes = Math.floor(num / 60)
  let hours = Math.floor((num / 60) / 60)
  let days = Math.floor(((num / 60) / 60) / 24)
  if (days) {
    hours -= days * 24
    minutes -= (days * 24 * 60)
  }
  let out = ''
  if (days || hours) out += `${formatNumber(days ? hours + (days * 24) : hours)}h`
  if (!round || (!hours && !days)) out += `${hours || days ? ` ${minutes - (hours * 60)}` : minutes}m`
  return out
}

export const formatRaceTime = (num) => {
  let hours = Math.floor((num / 60) / 60)
  let minutes = Math.floor((num - (hours * 3600)) / 60)
  let seconds = num - (hours * 3600) - (minutes * 60)
  let out = ''
  if (hours && hours < 10) {
    out += `0${hours}:`
  } else if (hours) {
    out += `${hours}:`
  } else {
    out += '00:'
  }
  out += `${minutes < 10 ? `0${minutes}`: minutes}:${seconds < 10 ? `0${seconds}`: seconds}`
  return out
}

export const trunc = (str, n = 1000) => {
  return (str.length > n) ? `${str.substr(0, n - 1)} ⋯` : str
}

export const capitalizeFirstLetter = (string) => {
  if (!string) return ''
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const formatMoneyIntl = (amount, currency) => {
  try {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency.toUpperCase() || 'USD'
    })
    return formatter.format(amount)
  } catch (err) {
    console.error(err)
    return `${formatMoney(amount, 2)} ${currency.toUpperCase()}`
  }
}

export const formatMoney = (amount, decimalCount = 2, decimal = '.', thousands = ',') => {
  try {
    decimalCount = Math.abs(decimalCount)
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount
    const negativeSign = amount < 0 ? '-' : ''
    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString()
    let j = (i.length > 3) ? i.length % 3 : 0
    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : '');
  } catch (e) {
    console.log(e)
  }
}

export const isImperial = () => {
  let lang
  if (typeof window === 'undefined') return false // default
  if (window.navigator.languages) {
    lang = window.navigator.languages[0]
  } else {
    lang = window.navigator.userLanguage || window.navigator.language
  }
  if (lang === 'en-US') return true
  return false
}
